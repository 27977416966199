import React from "react"
import "../scss/global.scss"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Tile from "../components/Tile"
import SEO from "../components/SEO"

const index = () => {
  return (
    <Layout>
      <SEO title="Casa di ispirazione" description="Ispiraci" />
      <Hero title="Ispiraci" />
      <div className="flex items-center">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-around">
            <div>
              <Tile text="Aggiungi" to="stile-aggiungi" />
            </div>
            <div>
              <Tile text="Vedi i nostri" to="/neonail.it/ispirazioni/" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default index
